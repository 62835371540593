.invoice-canvas .react-pdf__Page .react-pdf__Page__canvas{
  margin : auto;
}

.invoice-canvas.preview .react-pdf__Thumbnail .react-pdf__Thumbnail__page .react-pdf__Thumbnail__page__canvas{
  width: 100% !important;
  height: 100% !important;
}

.invoice-canvas.preview:hover{
  background: var(--secondary-button-color);
}

.invoice-canvas.preview {
  width: 100%;
  overflow: hidden;
  position: relative;
  height: 160px;
}

.invoice-canvas.preview .react-pdf__Thumbnail__page {
  transform-origin: top;
  height: 200%;
}

.invoice-canvas.preview .react-pdf__Thumbnail__page__canvas {
  width: 100% !important;
  height: 50% !important;
  display: block;
  margin: 0 auto;
}

.invoice-card:hover{
  background: var(--secondary-button-color);
}