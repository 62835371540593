/* profile */
.upload-ticket-type-image .ant-upload.ant-upload-select{
    width: 100% !important;
}

.upload-park-image .ant-upload.ant-upload-select{
    margin: 0 !important;
    background : #fff !important;
}

.card-overlay {
    background: rgba(0, 0, 0, 0.4);
}

/* calendar */
.calendar-wrapper .rbc-off-range-bg{
    background-color: var(--secondary-button-color);
}

th.discount-ticket-type{
    background-color: #1d39c4 !important;
    color : #fff !important;
}